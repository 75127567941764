<template>
    <WidgetWrapper :widget="widget" :class="isMobile && 'mobile_widget'">
        <TextViewer 
            v-if="widgetText"
            class="body_text"
            :body="widgetText" />
    </WidgetWrapper>
</template>

<script>
import TextViewer from '@apps/CKEditor/TextViewer.vue'
import WidgetWrapper from '../WidgetWrapper.vue'
export default {
    components: {
        TextViewer,
        WidgetWrapper
    },
    props: {
        widget: {
            type: Object,
            required: true
        }
    },
    computed: {
        widgetText() {
            return this.widget.widget.random_html || null
        },
        isMobile() {
            return this.$store.state.isMobile
        }
    }
}
</script>

<style lang="scss" scoped>
.body_text{
    height: 100%;
    overflow: auto;
}
.mobile_widget{
    .body_text{
        max-height: 300px;
    }
}
</style>